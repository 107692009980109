@tailwind base;
@tailwind components;
@tailwind utilities;
@config "../../tailwind.config.js";
@import "https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap";
@import "animations";

@layer base {
    html {
        font-family: theme("fontFamily.normal");
        font-size: 14px;
        color: theme("colors.blue-medium");
    }
}

.gradient {
    background: linear-gradient(to right bottom,
            theme("colors.blue-dark") 0%,
            theme("colors.turquoise") 51%,
            theme("colors.blue-dark") 100%);
}

.text-smallcaps {
    font-variant: small-caps;
}

.visible {
    visibility: visible !important;
}

.items-center {
    align-items: center !important;
}

.z-1000 {
    z-index: 1000 !important;
}

.z-0 {
    z-index: 0 !important;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;
}

@media (width >=640px) {
    .container {
        max-width: 640px;
    }
}

@media (width >=768px) {
    .container {
        max-width: 768px;
    }
}

@media (width >=1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (width >=1200px) {
    .container {
        max-width: 1200px;
    }
}

.opacity-25 {
    background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
    opacity: 0.25 !important;
}

.nine-btn-primary {
    display: inline-block;
    background: linear-gradient(to right,
            theme("colors.blue-nine") 0%,
            theme("colors.turquoise") 51%,
            theme("colors.blue-nine") 100%);
    color: white;
    letter-spacing: 0;
    font-family: theme("fontFamily.accent");
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    padding: 0 38px;
    outline: none !important;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    background-size: 200% auto;

    &:hover {
        background-position: right center;
        color: white;
        box-shadow: 0 0 30px -15px rgb(46 54 78 / 20%);
    }
}

.text-muted {
    color: #a3a6a8 !important;
}

/* HEADER */
.sticky-bar {
    font-family: theme("fontFamily.accent");
    color: #fff;
    position: relative;
    z-index: 3;

    .menu-item {
        @apply font-semibold;

        border-bottom: 4px solid transparent;

        &:hover:not(.active) {
            background-color: rgba(106 106 106 / 30%);
            border-radius: 6px 6px 0 0;
            border-bottom: 4px solid theme("colors.turquoise");
            transition-duration: 0.6s;
            transition-delay: 0.3s;
            transition-timing-function: ease-out;
        }

        &.active {
            font-weight: 500;
            color: theme("colors.turquoise");
            text-transform: uppercase;
            border-bottom: 2px solid theme("colors.turquoise");
        }
    }

    &.stick {
        animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        box-shadow: 0 8px 10px 0 rgb(0 0 0 / 15%);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        left: 0;
        transition: all 0.3s ease 0s;
        background: theme("colors.blue-dark");
        color: #fff;
        margin-top: 0 !important;

        .menu-item:hover {
            color: theme("colors.turquoise");
        }
    }
}

.navbar-menu {
    animation: slide 700ms forwards;
    animation-delay: 2s;

}

.btn-nine {
    @apply transform transition ease-in-out;

    color: theme("colors.perfect") !important;

    &:hover {
        @apply scale-105 shadow-lg bg-blue-gray-400 p-2 rounded;

        color: #fff;
        text-decoration: none;
    }
}

/* HOME */
.home-area {
    background: url("https://carolinenoyer.fr/images/volcans-cnoyer.png") no-repeat 50%;
    background-size: cover;
    height: 100vh;
    padding-top: 130px;
    position: relative;
    z-index: 1;
}

.btn-social {
    @apply text-white shadow-lg rounded-full border-blue-gray-400 border-2;

    transition: 0.5s;
    transition-delay: 0.3s;

    &:hover {
        border-color: theme("colors.turquoise");
        background: linear-gradient(to right bottom,
                theme("colors.blue-dark") 0%,
                theme("colors.turquoise") 51%,
                theme("colors.blue-dark") 100%);
    }
}

#about p strong {
    @apply text-blue-nine;
}

.subtitle::after {
    content: '';
    width: 50px;
    height: 1px;
    display: inline-block;
    background-color: #e1e1e1;
    margin: 0 15px;
    transform: translateY(-4px);
}

.skill-picto {
    @apply p-3 text-center mb-3 mx-auto text-yellow-nine text-4xl;
}

.skill-title {
    @apply text-2xl mb-2 font-semibold leading-normal text-center text-white;
}

.skill-item {
    @apply text-xs font-semibold inline-block py-1 px-2 rounded-full text-blue-nine bg-light/50 uppercase mr-2 mt-2;
}

/* FOOTER */
.footer {
    @apply text-blue-gray-400;

    background-color: theme("colors.blue-dark");
}

.list-inline {
    padding-left: 0;
    list-style: none;

    .list-inline-item {
        display: inline-block;
    }

    .list-inline-item:not(:last-child) {
        margin-right: 0.5rem;
    }
}

/* Experience */
.bg-img-1 {
    background: url('../../public/images/global/background-path-2.svg') no-repeat top center;
}

.bg-img-2 {
    background: url('../../public/images/global/background-path-2-gray.svg') no-repeat top center;
}

.missions i.fa-tasks {
    color: theme("colors.turquoise");
    margin-right: 5px;
}

.bordered-grey {
    margin: 10px 0 0 20px;
    padding-left: 10px;
    border-left: 3px solid theme("colors.blue-gray-400");
}

.line {
    background: linear-gradient(theme("colors.blue-dark") 0%, theme("colors.turquoise") 100%);
    opacity: 0.25;
    width: 200px;
    height: 2px;
    margin-bottom: 0.75rem;
    border-radius: 9px;
}

/* Skills */
.skill {
    @apply border-light border-solid;

    border-width: 1px !important;

    --tw-border-opacity: 0.5;
}