/* Animation */
@keyframes jump {
    0% {
        transform: translate3d(0, 15px, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes jump {
    0% {
        transform: translate3d(0, 15px, 0);
    }
    
    100% {
        transform: translate3d(0, 0, 0);
    }
}

.jump {
    transform-origin: 0;
    animation: jump 1s linear alternate infinite;
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}
